// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase, connectDatabaseEmulator } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyBgXc6VlYb2hhbpN--JgjJS_8VeOh9Fqbk",
  authDomain: "vetlinksolutions-kimbodo.firebaseapp.com",
  databaseURL: "https://vetlinksolutions-kimbodo-default-rtdb.firebaseio.com",
  projectId: "vetlinksolutions-kimbodo",
  storageBucket: "vetlinksolutions-kimbodo.appspot.com",
  messagingSenderId: "336447591828",
  appId: "1:336447591828:web:681dbf51564ea45c4f6b81",
  measurementId: "G-SE44X1D4YC"
};
// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
export const firebaseDb = getDatabase(firebaseApp);

if(window.location.hostname === 'localhost' || window.location.hostname === '192.168.86.40') {
  connectDatabaseEmulator(firebaseDb, "127.0.0.1", 9000);
}