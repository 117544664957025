<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
    <button 
      :disabled="gongClickDisable"
      class="gongButton"
      type="button" 
      ref="gongClick" 
      @click="enableGong">
      <img 
        style="height: 600px;"
        alt="Gong Logo" 
        src="../assets/gong.png" />
    </button> 
    <h1>Gong has gonged: {{ gongCount }}</h1>
  </div>
</template>

<script>
const gongSound = require("@/assets/gong.mp3");
import { ref, onMounted } from "vue"
import { useDatabase } from 'vuefire'
import { ref as dbRef, set, get, onValue } from "firebase/database";

export default {
  name: 'GongMe',
  data: () => ({
    gongSound
  }),
  props: {
    msg: String
  },
  setup() {
    const gongCount = ref(0);
    const gongClick = ref();
    const gongIt = ref(false);
    const gongClickDisable = ref(false);

    const gongRef = dbRef(useDatabase(), "gong");
    const gongCountRef = dbRef(useDatabase(), "gongCount");

    onValue(gongRef, (snapshot) => {
      gongIt.value = snapshot.val();
      if (gongIt.value) {
        soundGong();
      }
    });

    onValue(gongCountRef, (snapshot) => {
      gongCount.value = snapshot.val();
    });

    onMounted(() => {
      getGongCount();
    })

    const getGongCount = () => {
      get(gongCountRef).then((snapshot) => {
        if (snapshot.exists()) {
          gongCount.value = snapshot.val();  
        }
      });
    }

    const soundGong = () => {
      let note = new Audio(gongSound);
      note.autoplay = true;
      note.muted = false;
      note.play();

      getGongCount();
    };

    const enableGong = () => {
      console.log(gongClickDisable.value);
      console.log(gongIt.value);

      if (!gongIt.value && !gongClickDisable.value) {
        gongIt.value = true;
        gongClickDisable.value = true;

        set(gongRef, true);

        get(gongCountRef).then((snapshot) => {
          if (snapshot.exists()) {
            const count = parseInt(snapshot.val()) + 1;
            set(gongCountRef, count).then(() => {
              gongCount.value = count;
            });
          }
        });

        setTimeout(() => {
          set(gongRef, false);
          gongClickDisable.value = false;
        }, 5000);
      }
    }

    return {
      gongClickDisable,
      gongCount,
      gongClick,
      enableGong,
      gongIt      
    }
  }
}
</script>
<style>
.gongButton {
  border-radius: 10px;
  border-color: black;
  background-color: transparent;
}
.gongButton:hover {
  cursor: pointer;
}
</style>